import {
  no_scroll,
  return_scroll
} from '../common/_common';
import 'lazyload';

const synopsis = $('#synopsis').length;
const synopsisSingle = $('#synopsisSingle').length;
const breakPoint = 750;

let images = document.querySelectorAll(".lazyLoad");

lazyload(images);

function equalBoxHeight(elm, num) {
  elm = $(elm);
  elm.css("height", "auto");
  elm.each(function (i) {
    let height;
    let $this = $(this);

    if (i === 0 || i % num === 0) {
      height = $this.height();

      for (let n = i + 1; n <= i + num - 1; n++) {
        if (height < elm.eq(n).height()) {
          height = elm.eq(n).height();
        }
      }

      for (let n = i; n <= i + num - 1; n++) {
        elm.eq(n).css("height", height + "px");
      }
    }
  });
}

export function synopsisCardHeight() {
  if (synopsis) {
    
    if (breakPoint < window.innerWidth) {

      $('.synopsisCardVisual img').on('load', function() {
        equalBoxHeight('.synopsisCardVisual', 4);
      });

      equalBoxHeight('.synopsisCardVisual', 4);

    } else {

      $('.synopsisCardVisual img').on('load', function() {
        equalBoxHeight('.synopsisCardVisual', 2);
      });

      equalBoxHeight('.synopsisCardVisual', 2);
    }
  }
}

export function synopsisBackUrl() {
  if(synopsisSingle){
    var ref = document.referrer;
    var hereHost = window.location.hostname;

    const url = new URL(ref);
    // url.searchParams.delete('id');
  
    const synopsisCardId = document.getElementsByClassName('synopsisId')[0].innerText;
    let synopsisCardHash = "#";
    let synopsisCardIdNum = synopsisCardId.replace(synopsisCardHash, "");

    const searchParams = url.searchParams;
    const searchId = new URL(window.location.href).searchParams.get("search_num");
    
    // ホスト名が含まれるか探す正規表現を作る(大文字・小文字を区別しない)
    var sStr = "^https?://" + hereHost;
    var rExp = new RegExp( sStr, "i" );

    let locationBackUrl;
    
    // リファラ文字列を判別
    if( ref.length == 0 ) {
        // リファラなしの場合
        locationBackUrl = '/synopsis/';
    }
    else if( ref.match( rExp ) ) {
        // マッチした場合＝アクセス元が自サイト内の場合
        locationBackUrl = ref + synopsisCardId;

        if(searchParams.has('s')){
          // リファラなしの場合
          locationBackUrl = ref + "#" +searchId;
        }
    }
    else {
        // マッチしない場合＝アクセス元がサイト外の場合
        locationBackUrl = '/synopsis/';
    }

    $('.synopsisBtn').on('click', function() {
      $(this).attr("href", locationBackUrl);
    });

  }
}

export function ajaxFunc() {
  if (synopsis) {
    //const url = "https://dev.readjapan.org/wp/wp-admin/admin-ajax.php";
    const url = "https://readjapan.org/wp/wp-admin/admin-ajax.php";
    let ajaxFlag = true;
    let count = 0;

    const catTit = document.querySelectorAll('.synopsisColumnTitle');
    const cardList = document.querySelectorAll('.synopsisCardLayout');
    let postNum = [];
    let termsList = [];
    const maxNum = cardList.length;
    for (let i = 0; i < cardList.length; i++) {
      let num = cardList[i].children[0].textContent;
      postNum.push(num);
      let term = cardList[i].classList.item(1);
      termsList.push(term);
    }

    function ajax_function(postData) {
      return $.ajax({
        url: url,
		headers: {  'Access-Control-Allow-Origin': '*' },
        type: "POST",
        data: postData
      })
    }

    const callback = (function (changes) {
      for (let change of changes) {
        let target = change.target;
        if (change.isIntersecting && ajaxFlag && count < cardList.length) {
          let post = cardList[count].children;
          let offset = post.length - 1;
          let terms = termsList[count];
          if (offset == postNum[count]) {
            count++;
            if (count < cardList.length) {
              post = cardList[count].children;
              offset = post.length - 1;
              terms = termsList[count];
            }
          }
          if (maxNum > count) {
            ajaxFlag = false;
            no_scroll();
            target.classList.add('loading');
            let postData = {
              'action': 'my_ajax_get_posts',
              'offset': offset,
              'terms': terms,
            };
            setTimeout(() => {
              ajax_function(postData).done(function (data) {
                if (count < cardList.length) {
                  catTit[count].classList.add('active');
                  cardList[count].insertAdjacentHTML("beforeend", data);
                }
              }).fail(function (xhr, status, error) {
                console.log("XMLHttpRequest : " + xhr.status);
                console.log("textStatus     : " + status);
                console.log("errorThrown    : " + error.message);
              }).always(function () {
				  target.classList.remove('loading');
                  ajaxFlag = true;
                  return_scroll();	
				  var y = $(window).scrollTop();  //your current y position on the page
				  $(window).scrollTop(y-500);
              });
            }, 1000);
          }
        }
      }
	  synopsisCardHeight();
    });

    const options = {
      rootMargin: "0px 0px -100px",
    };

    const observer = new IntersectionObserver(callback, options);

    const target = document.querySelector('#synopsisLoadPoint');
    observer.observe(target);
  }
}
