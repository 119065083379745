import {
	scrollControlFunk,
} from "../common/_common";

export function buttonActionFunc() {
  /*検索フォーム*/
  let valSearchBtn, valSearchLayout, valMenuTrigger, headerH;
  valSearchBtn = $('#searchBtn');
  valSearchLayout = $('#searchLayout');
  valMenuTrigger = $('.menuTrigger');

  /*検索ボタン*/
  let setInH, resizecheck, resDiff3;
  valSearchBtn.on('click', function () {
    headerH = $('#header').outerHeight(true);
    setInH = $('.inside').outerHeight(true);

    resizecheck = $('#responsibleCheck').width();
    resDiff3 = headerH + valSearchLayout.outerHeight(true);


    valSearchLayout.toggleClass('addOpen');
    if ($(valSearchLayout).hasClass('addOpen')) {
      if (resizecheck == '2') {
        valSearchLayout.css('top', setInH);
      } else {
        valSearchLayout.css('top', resDiff3);
      }
    } else {
      if (resizecheck == '2') {
        //spの処理
        valSearchLayout.css('top', -60);
      } else {
        valSearchLayout.css('top', -headerH);
      }

    }
  });

  /*グローバルメニュー開閉*/
  valMenuTrigger.on('click', function () {

    if ($('#searchLayout').hasClass('addOpen')) {
      valSearchLayout.removeClass('addOpen');
      valSearchLayout.css('top', -60);
    }


    $(this).toggleClass('active');
    headerH = $('#header').outerHeight(true);
    if ($(this).hasClass('active')) {
      $('.globalTopNav').css('top', headerH).addClass('open');
      $('.spNavigations').addClass('open');
      scrollControlFunk('stop');
    } else {
      $('.globalTopNav').css('top', 0).removeClass('open');
      $('.spNavigations').removeClass('open');
      scrollControlFunk('release');
    }
  });

  /*synopsisアコーディオン*/
  let valSynopsisAccordion = $('#synopsisToggleBtn');
  let valSynopsisAccordionList = $('#synopsisToggleList');
  valSynopsisAccordion.on('click', function () {
    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
      valSynopsisAccordionList.removeClass("open");
    } else {
      $(this).addClass("open");
      valSynopsisAccordionList.addClass("open");
    }
    return false;
  });

  /*Recipient Institutionアコーディオン*/
  let valRecipientAccordion = $('.activeSp');
  let valCategoryMenu = $('.categoryMenu');
  let changeJs = $('.categoryList a');
  let changeJsChild = $('.childCategoryList a');
  valRecipientAccordion.on('click', function () {

    if (valRecipientAccordion.hasClass("open")) {
      valRecipientAccordion.removeClass("open");
    } else {
      setTimeout(function () {
        valRecipientAccordion.addClass("open");
      }, 600);
    }

    if (valCategoryMenu.hasClass("open")) {
      valCategoryMenu.removeClass("open");
    } else {
      setTimeout(function () {
        valCategoryMenu.addClass("open");
      }, 600);
    }

    let categoryAreaTop = $('.categoryArea').offset().top - 100;
    $("html, body").animate({
      scrollTop: categoryAreaTop
    }, 400, "easeOutCubic");
    return false;
  });

  changeJs.on('click', function () {
    let currentName = $(this).html();
    $('.selectBtn').html(currentName).write;

    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
    }

    if (valRecipientAccordion.hasClass("open")) {
      valRecipientAccordion.removeClass("open");
    }

    if (valCategoryMenu.hasClass("open")) {
      valCategoryMenu.removeClass("open");
    }

    return false;
  });
  changeJsChild.on('click', function () {
    let currentName = $(this).html();
    $('.selectBtn').html(currentName).write;

    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
    }

    if (valRecipientAccordion.hasClass("open")) {
      valRecipientAccordion.removeClass("open");
    }

    if (valCategoryMenu.hasClass("open")) {
      valCategoryMenu.removeClass("open");
    }

    return false;
  });

  let categoryPageScroll = $('.recipientPageScroll');
  categoryPageScroll.on('click', function () {
    var pos;
    resizecheck = $('#responsibleCheck').width();
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var speed = Math.abs($(window).scrollTop() - position) / 2;
    var headerHeight = $('#header').outerHeight(true);
    if (resizecheck == '2') {
      pos = position - headerHeight;
    } else{
      pos = position;
    }
    $('body,html').animate({
      scrollTop: pos
    }, speed, 'easeInOutCubic');
    return false;
  });

  let followUpBtn = $('.followUpButton img');
  followUpBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 750) {
      followUpBtn.fadeIn();
    } else {
      followUpBtn.fadeOut();
    }
    return false;

  });

  $(window).scroll(function () {
    let check = window.pageYOffset;
    let docHeight = $(document).height();
    let dispHeight = $(window).height();
    if (check > docHeight - dispHeight - 280) {
      followUpBtn.addClass("hide");
    } else {
      followUpBtn.removeClass("hide");
    }
    return false;
  });

  followUpBtn.on("click", function () {
    let resizecheck = $('#responsibleCheck').width();
    let targetTop = $('.categoryColumn').offset().top;
    if (resizecheck == '2') {
      $("html, body").animate({
        scrollTop: $('.categoryColumn').offset().top - 40
      }, 1600, "easeOutCubic");
    } else {
      $("html, body").animate({
        scrollTop: targetTop
      }, 1600, "easeOutCubic");
    }
    return false;
  });

  const url = $(location).attr('href');
  if (url.indexOf("?id=") != -1) {
    const id = url.split("?id=");
    const $target = $('#' + id[id.length - 1]);
    let resizecheck = $('#responsibleCheck').width();
    if ($target.length) {
      if (resizecheck == '2') {
        //spの処理はヘッダーの高さ分差し引く
        const headerHeight = $('#header').outerHeight(true);
        const pos = $target.offset().top - headerHeight;
        $("html, body").animate({
          scrollTop: pos
        }, 1500, 'easeInOutCubic');
      } else {
        const pos = $target.offset().top - 20;
        $("html, body").animate({
          scrollTop: pos
        }, 1500, 'easeInOutCubic');
      }

    }
  }


    /* ----------------------------------------------------------------------------------
    モーダルウィンドウ
    ---------------------------------------------------------------------------------- */
    var detailModalFunc = {
      init: function(onLoad) {
        if(onLoad) this._init();
      },
      _init: function() {
        // 設定
        var animateSpeed = 800;
        var closeElem = '.close a';
        var btnCloseElem = '.closeBtn';
        var mainModalContent = '.detailModalContent';
        var myModalHeight;
        var pointScrollY;
        var currentScrollY;
  
        $(window).scroll(function() {
          pointScrollY = $(window).scrollTop();
        });
  
        // スライドされる高さの調整
         $(window).on('load resize', function() {
           var windowHeight = $(window).outerHeight();
           $(mainModalContent).find('.modalInner').css({'height':windowHeight}); //高さを出すスクロールを表示
         });
  
        $(mainModalContent).each(function(i) {
          i++;
          //console.log(i);
          $('.modal' + i).on('click touchstart', function() {
            // 背景固定
             currentScrollY = $(window).scrollTop();
            $('body').css({
              position: 'fixed',
              width: '100%',
              top: -1 * currentScrollY,
            });
  
            $('#modal' + i).fadeIn(animateSpeed);
  
            // 上下縦横中央寄せに実行
            var windowHeight = $(window).outerHeight(); // 縦方向の余白を保つ
            myModalHeight = $('#modal' + i).outerHeight();
            if(windowHeight <= myModalHeight) {
              myModalHeight = windowHeight;
              $(mainModalContent).css({'height':'100%'});
            }
            centeringModalSyncer(myModalHeight);
          });
  
          function closeAction() {
            // 背景を固定を解除
            $('body').attr({style:''});
            $('html,body').animate({scrollTop:currentScrollY},1);
            $(mainModalContent).stop().fadeOut(animateSpeed);
          }
  
          // クリックでモーダルをクローズ
          $(closeElem).on('click', function() {
            closeAction();
          });
        });
        $(window).on('resize', centeringModalSyncer);
  
        // 上下縦横中央寄せの関数
        function centeringModalSyncer(myModalHeight) {
          $(mainModalContent).css({
            'left': '0px',
            'top': '0px'
          });
        }
      }
    }
    detailModalFunc.init(true);
  
    /*books*/
    let pageAccordionBtn = $('#pageAccordionBtn');
    let pageAccordionBox = $('#pageAccordionBox');
    pageAccordionBtn.on('click', function () {
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
        pageAccordionBox.removeClass("open");
      } else {
        $(this).addClass("open");
        pageAccordionBox.addClass("open");
      }
      return false;
    });
    let pageNateItemCount = $('.custom ol li.page').length;
    let pageNateItem = $('.custom ol li.page');
    if(pageNateItemCount < 20){
      $('.custom ol').addClass('center');
    } else{
      $('.custom ol').removeClass('center');
    }
    // if(pageNateItemCount > 5){
    //   let pageNateNum = $('.custom ol li.page.current').text() - 1;
    //   let pageNateAllNum = pageNateItemCount - 1;
  
    //   console.log(pageNateNum); 
    //   console.log(pageNateAllNum);
      
    //   if(pageNateNum == 0){
    //       pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
    //   } else if(pageNateNum == 1){
    //       pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
    //   } else if(pageNateNum == 2){
    //       pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
    //       pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    //   }  else if(pageNateNum == 3){
    //     pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
    //     pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    //   } else if(pageNateNum == pageNateAllNum){
    //       pageNateItem.slice(0, pageNateNum - 4).css('display', 'none');
    //   } else if(pageNateNum == pageNateAllNum - 1){
    //      pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
    //      pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
    //   }  else if(pageNateNum == pageNateAllNum - 2){
    //     pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
    //     pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
    //  } else {
    //      pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
    //      pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
    //   }
      
    // }
}
