import Swiper from 'swiper';
//トップページ　キービジュアルスライダー
export function idxFunc() {
	var topSwiper, topOptions;
	topSwiper = undefined;
	topOptions = {
		loop: true,
		autoplay: {
			delay: 5000
		},
		speed:2000,
		effect: 'fade',
		normalizeSlideIndex: true,
		paginationClickable: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
	};
	//スワイプ実行処理
  function initTopSwiper() {
    topSwiper = new Swiper('#topSlide', topOptions);
  }
  initTopSwiper();
}

//モデルスライダー
export function idxNewsSliderFunc() {
	var newsSwiper, newsOptions, responsibleCheck, timer, swiperWrapper, swiperSlide;
  let swiperSwitch;
	
	swiperSwitch = false;
	newsSwiper = undefined;
  timer = false;
  responsibleCheck = $('#responsibleCheck').width();
  swiperWrapper = $('#newsSwiper').find('.swiper-wrapper');
  swiperSlide = $('#newsSwiper').find('.swiper-wrapper').find('.swiper-slide');
	
	newsOptions = {
    loop: false,
    speed: 800,
    slidesPerView: 4,
    spaceBetween: 10,
    effect: 'slide',
    normalizeSlideIndex: true,
    paginationClickable: true,
    navigation: {
      nextEl: '.newslNext',
      prevEl: '.newslPrev',
    },
  };
	
	//スワイプ実行処理
  function initNewsSwiper() {
    swiperSwitch = true;
    setTimeout(function () {
      newsSwiper = new Swiper('#newsSwiper', newsOptions);
    }, 100);
  }
	
	//スワイプ終了処理
  function resetNewsSwiper() {
    swiperSwitch = false;
    setTimeout(function () {
      for (var i = 0; i < swiperWrapper.length; i++) {
        swiperWrapper[i].removeAttribute('style');
      }
      for (var i = 0; i < swiperSlide.length; i++) {
        swiperSlide[i].removeAttribute('style');
      }
      newsSwiper.destroy(true, true);
      newsSwiper = undefined;
    }, 100);
  }
	
	//初回読み込み時
  if (responsibleCheck == 1) {
    initNewsSwiper();
  } else {
    swiperSwitch = false;
  }
	
	//リサイズ処理
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      //リサイズ終了後の処理
      responsibleCheck = $('#responsibleCheck').width();
      if (responsibleCheck == 1 && swiperSwitch == false) {
        initNewsSwiper();
      } else if (responsibleCheck == 2 && swiperSwitch == true) {
        resetNewsSwiper();
      }
    }, 200);
  });
	
}








