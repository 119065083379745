import {
	scrollControlFunk,
} from "../common/_common";

export function headerFunc() {
		$('#stopJsScroll').on('click', function () {
		scrollControlFunk('stop');
	});
	$('#releaseJsScroll').on('click', function () {
		scrollControlFunk('release');
	});


  var ref = window.location;
  const url = new URL(ref);
	const searchParams = url.searchParams; 
	const param = searchParams.get('search_word');

	var main = document.querySelector("main");

	if( searchParams.has('search_word') ) {
		//テキストノード の抽出
		function getTextNodes(node) {
			const textNodes = [];
			if (node.nodeType === Node.TEXT_NODE) {
					textNodes.push(node);
			} else if (node.nodeType === Node.ELEMENT_NODE) {//エレメントだったら子ノードからテキストを抽出
					for (const childNode of node.childNodes) {
							textNodes.push(...getTextNodes(childNode));//配列をマージさせる
					}
			}
			return textNodes;
		}

		const textNodes = getTextNodes(main);
		
		textNodes.forEach(textNode => {
			const textContent = textNode.nodeValue;
			const regex = new RegExp(param, "gi"); // 大文字小文字の区別をつけない

			textContent.replace(regex, match => {
				//spanタグを生成
				const spanElement = document.createElement('span');
				//spanElement.style.cssText = "color:red";
				spanElement.className = 'highlight';
				spanElement.textContent = match;

				//前後の文章を取得
				const beforeText = document.createTextNode(textContent.substring(0, textContent.indexOf(match)));//最初から指定文字まで
				const afterText = document.createTextNode(textContent.substring(textContent.indexOf(match) + match.length));//指定文字から後ろ

				//文字列を置換
				textNode.replaceWith(beforeText, spanElement, afterText);
			});
		});

		$('.highlight').first().addClass('highlightScroll');
		var headerHeight, position;
		$('.highlightScroll').each(function () {
			headerHeight = $('#header').outerHeight(true);
			position = $(this).offset().top - headerHeight - 20 ;
			$('body,html').animate({
				scrollTop: position
			}, 1000, 'easeInOutQuart');
			return false;
		});
	}

	// $("main").each(function(i) {
	// 	const elem = $(this);
	// 	const elemTxt = elem.html();// 対象の文字列を取得
	// 	const replaceTxt = elemTxt.replace(new RegExp(param, "gi"), function(match) {
	// 		return '<span style="color:red">' + match + '</span>';
	// 	});
	// 	elem.html(replaceTxt);// 差し替え
	// });
}

export function headerResizeFunc() {
}