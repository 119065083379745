// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight, position;
  $('a[href^="#"]:not(.notScroll)').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];

      $(this).on('click', function () {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top - headerHeight ;
        $('body,html').animate({
          scrollTop: position
        }, 1000, 'easeInOutQuart');
        return false;
      });
    }
  });

  // let headerHashHeight = $('#header').outerHeight(true);
  // let resizecheck = $('#responsibleCheck').width();
  // let urlHash = location.hash;
  // if (urlHash) {
  //   if (resizecheck == '2') {
  //     // let position = $(urlHash).offset().top - headerHashHeight;
  //     // $("html, body").scrollTop(position);
      
  //     $('body,html').stop().scrollTop(0);
  //     setTimeout(function(){
  //         var target = $(urlHash);
  //         var position = target.offset().top - headerHashHeight;
  //         $('body,html').stop().animate({scrollTop:position}, 0);
  //     }, 100);
  //   }
  // }
}