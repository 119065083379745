//背景(スクロール)固定処理
let fixdScrollpos;
export function scrollControlFunk(res) {
  //背景固定
  function controlStop() {
    fixdScrollpos = $(window).scrollTop();
    $('body').addClass('fixedBody').css({
      'top': -fixdScrollpos
    });
  }
  //背景固定解除
  function controlRelease() {
    $('body').removeClass('fixedBody').css({
      'top': 0
    });
    window.scrollTo(0, fixdScrollpos);
  }
  switch (res) {
    case 'stop':
      /*背景固定*/
      controlStop();
      break;
    case 'release':
      /*背景固定解除*/
      controlRelease();
      break;
  }
}

export function resizeMainTop() {
  let getheaderH, resizecheck, grtNavH, resDiff;
  let getInsideH, searchH, resDiff2;

  resizecheck = $('#responsibleCheck').width();
  getheaderH = $('#header').outerHeight(true);
  getInsideH = $('.inside').outerHeight(true);
  grtNavH = $('.spNavigations').outerHeight(true);
  searchH = $('#searchLayout').outerHeight(true);

  resDiff = getInsideH - grtNavH;
  /*検索フォーム*/
  let setSearchTop;
  $('#searchLayout').css('top', -getheaderH);

  //検索フォームの調整
  resDiff2 = getInsideH - searchH;
  if (resizecheck == '2') {
    $('main').css('margin-top', getInsideH);
    $('.spNavigations').css('top', 60);
    $('#searchLayout').css('top', resDiff2);
  } else {
    $('main').css('margin-top', 0);
  }
}

/** スクロール一時禁止 **/
//let scrollControl = function (e) {
//  e.preventDefault();
//}
//export function no_scroll() {
//  document.addEventListener("mousewheel", scroll_control, {
//    passive: false
//  });
//  document.addEventListener("touchmove", scroll_control, {
//    passive: false
//  });
//}
//export function return_scroll() {
//  document.removeEventListener("mousewheel", scroll_control, {
//    passive: false
//  });
//  document.removeEventListener('touchmove', scroll_control, {
//    passive: false
//  });
//}

/** スクロール一時禁止 **/
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault) {
    e.preventDefault();
    e.returnValue = false;
  }
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

const userAgent = window.navigator.userAgent.toLowerCase();

export function no_scroll() {
  if (window.addEventListener) { // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
    document.addEventListener("mousewheel", preventDefault, { passive: false });
	document.addEventListener("wheel", preventDefault, { passive: false });
    document.addEventListener("touchmove", preventDefault, { passive: false });
    document.onkeydown = preventDefaultForScrollKeys;
    if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    } else if (userAgent.indexOf('edge') != -1) {
      window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    } else if (userAgent.indexOf('chrome') != -1) {
    } else if (userAgent.indexOf('safari') != -1) {
    } else if (userAgent.indexOf('firefox') != -1) {
      //window.onwheel = preventDefault; // modern standard
    } else if (userAgent.indexOf('opera') != -1) {
    }
  }
}

export function return_scroll() {
  if (window.removeEventListener) {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    document.removeEventListener("mousewheel", preventDefault, { passive: false });
	document.removeEventListener("wheel", preventDefault, { passive: false });
    document.removeEventListener("touchmove", preventDefault, { passive: false });
    document.onkeydown = null;
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
  }
}
