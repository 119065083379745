import objectFitImages from 'object-fit-images';
import {
  scrollControlFunk,
  resizeMainTop
} from "./common/_common";
import {
  headerFunc,
  headerResizeFunc
} from "./common/_header";
import {
  hoverFunc,
  telLinkFunc,
  uaHoverFunc,
  hoverOtherFunc,
  animeLinkFunc
} from './common/_hoverFunc';
import {
  heightLine,
  heightLineSetFunc,
  heightLineFunc
} from './common/_heightLine';
import {
  pageLinkAnimationFunc,
  pageMoveAnkerFunc
} from './common/_pageLinkAnimation';
import {
  buttonActionFunc
} from "./common/_buttonAction";
import {
  idxFunc,
  idxNewsSliderFunc
} from "./pages/_index";
import {
  synopsisCardHeight,
  ajaxFunc,
  synopsisBackUrl
} from "./pages/_synopsis";
//* -------------------------------------------------------------------
//通常実行
//------------------------------------------------------------------- */
let setTimer = null;
//* ----------------------------------------------------------------------------------
//window実行
//---------------------------------------------------------------------------------- */
//オブジェクトフィット使用
objectFitImages();
let indexTop = $('#indexTop').length;

////// ロード時
$(window).on('load', function () {

  //トップページのみ
  if (indexTop) {
    idxFunc();
    idxNewsSliderFunc();
  }
  //ヘッダー関連
  headerResizeFunc();
  headerFunc();
  //共通
  hoverFunc();
  resizeMainTop();
  animeLinkFunc();
  //アンカーリンク
  pageMoveAnkerFunc();
  pageLinkAnimationFunc();
  //高さJS
  heightLine();
  heightLineSetFunc();
  //Synopsis
  synopsisCardHeight();
  synopsisBackUrl();
  //ajaxFunc();
  buttonActionFunc();
});

// リサイズ時
$(window).on('resize', function () {
  clearTimeout(setTimer);
  setTimer = setTimeout(() => {
    headerResizeFunc();
    resizeMainTop();
    heightLineFunc();
    synopsisCardHeight();
  }, 200);
});

// スクロール時
$(window).on('scroll', function () {

});
///* ----------------------------------------------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
    navigator.userAgent.indexOf('iPhone') > 0) ||
  (navigator.userAgent.indexOf('iPod') > 0) ||
  (navigator.userAgent.indexOf('iPad') > 0) ||
  (navigator.userAgent.indexOf('Android') > 0) ||
  (navigator.userAgent.indexOf('BlackBerry') > 0) ||
  (navigator.userAgent.indexOf('PlayBook') > 0) ||
  (navigator.userAgent.indexOf('Kindle') > 0) ||
  (navigator.userAgent.indexOf('Silk') > 0) ||
  (navigator.userAgent.indexOf('Windows Phone') > 0) ||
  (navigator.userAgent.indexOf('Nokia') > 0)
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
} else {
  // PC時の処理
  uaFlag = 3;
}
/* ----------------------------------------------------------------------------------
IEだけの時
---------------------------------------------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}
